<template>
  <div class="add-revenue-details-modal-container">
    <div class="add-revenue-title">Add Revenue Details</div>
    <a-form :ref="formRef">
      <div class="add-revenue-fields-wrapper">
        <div class="add-revenue-candidate-name">Candidate Name</div>
        <div style="margin-top: 10px">
          <span class="add-revenue-name">{{ candidateName }}</span>
          <u class="resume-id-link">{{ resumeId }}</u>
        </div>
        <div style="margin-top: 20px">
          <span class="add-revenue-fields-title">
            Book Revenue In<span style="color: red">*</span></span
          >
          <a-form-item v-bind="validateInfos.revenueDate">
            <a-date-picker
              v-model:value="modelRef.revenueDate"
              class="add-revenue-date-fields"
              style="width: 100%"
            />
          </a-form-item>
        </div>
        <div style="margin-top: 20px">
          <span class="add-revenue-fields-title">
            Likely Joining Date<span style="color: red">*</span></span
          >
          <div>
            <a-form-item v-bind="validateInfos.joiningDate">
              <a-date-picker
                v-model:value="modelRef.joiningDate"
                class="add-revenue-date-fields"
                style="width: 100%"
              />
            </a-form-item>
          </div>
        </div>
        <div style="margin-top: 20px">
          <span class="add-revenue-fields-title">
            Offered Salary<span style="color: red">*</span></span
          >
          <a-form-item v-bind="validateInfos.joiningSalary">
            <a-input
              v-model:value="modelRef.joiningSalary"
              placeholder="Enter"
              class="add-revenue-input-fields"
            />
          </a-form-item>
        </div>
        <div style="margin-top: 20px">
          <span class="add-revenue-fields-title">
            Fee<span style="color: red">*</span></span
          >
          <a-form-item v-bind="validateInfos.termId">
            <a-select
              allowClear
              v-model:value="modelRef.termId"
              style="width: 100%"
              :options="termsFeesOptions"
              @change="updateValue"
            >
            </a-select>
          </a-form-item>
        </div>

        <div style="display: flex; margin-top: 30px; align-items: baseline">
          <div class="add-revenur-cancel-btn" @click="onClickCancel">
            Cancel
          </div>
          <div class="add-revenue-save-btn">
            <a-button @click="onAddVacancyRevenueDetails">Save</a-button>
          </div>
        </div>
      </div>
    </a-form>
  </div>
</template>
<script>
import { onMounted, ref, reactive, computed } from "vue";
import { Form, message } from "ant-design-vue";
import Services from "../../../services/apis";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import Utils from "../../../utils";

export default {
  setup(props, context) {
    const {
      params: { id },
    } = useRoute();

    let termsData = [];
    let candidateId = reactive("");
    let termId = reactive("");

    const useForm = Form.useForm;
    let termsFeesOptions = ref([]);
    const { resetFields, validate, validateInfos } = useForm(
      modelRef,
      rulesRef
    );

    let candidateName = computed(
      () => store.getters["workbenchStore/getCandidateName"]
    );

    let resumeId = computed(() => store.getters["workbenchStore/getResumeId"]);

    const rulesRef = reactive({
      revenueDate: [
        {
          required: true,
          message: "Please select book revenue in",
        },
      ],
      joiningDate: [
        {
          required: true,
          message: "Please select likely joining date",
        },
      ],
      joiningSalary: [
        {
          required: true,
          message: "Please add offered salary",
        },
      ],
      termId: [
        {
          required: true,
          message: "Please select fee",
        },
      ],
    });
    const store = useStore();
    let vacancyId = id;
    const router = useRouter();
    const formRef = ref();
    let modelRef = reactive({
      revenueDate: "",
      joiningDate: "",
      joiningSalary: 0,
      termId: "",
      value: 0,
    });
    const onClickCancel = () => {
      context.emit("onClickCancel", false);
    };
    const onAddRevenue = () => {
      onClickCancel();
    };

    const fetchTerms = (value) => {
      let companyId = computed(
        () => store.getters["workbenchStore/getCompanyId"]
      ).value;

      console.log("companyId", companyId);
      let tempData = [];
      //  modelRef.termId = "";
      termsFeesOptions.value.splice(0);
      Services.getAccountTerms(companyId)
        .then((response) => {
          if (response.status === 200) {
            let { data } = response;
            if (data.length > 0) {
              data &&
                data.map((term) => {
                  termsData.push({
                    id: term.id,
                    level: term.name,
                    isFixed: term.isFixed,
                    fee: term.fee,
                    percentage: term.percentage,
                    fixedFee: term.fixedFee ? term.fixedFee : 10000,
                  });
                });
            }
            let tempData = Utils.termArray(termsData);
            Object.assign(termsFeesOptions.value, tempData);
            modelRef.termId = computed(() => store.getters["workbenchStore/getTermId"]).value;
          }
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    };

    const onAddVacancyRevenueDetails = () => {
      candidateId = computed(
        () => store.getters["workbenchStore/getCandidateId"]
      ).value;

      updateValue();
      let payload = {
        id: id,
        data: {
          candidateId: candidateId,
          joiningDate: Utils.displayYearFirstNew(modelRef.joiningDate),
          revenueDate: Utils.displayYearFirstNew(modelRef.revenueDate),
          joiningSalary: parseInt(modelRef.joiningSalary),
          termId: modelRef.termId,
          value: modelRef.value,
        },
      };
      Services.addVacancyRevenueDetails(payload);
      validate()
        .then((response) => {
          const { data } = response;

          onClickCancel();
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    };

    const updateValue = () => {
      if (/^\d+$/.test(modelRef.joiningSalary) && modelRef.termId) {
        console.log("modelRef.termId :>> ", modelRef.termId);
        let salary = modelRef.joiningSalary;
        let selectedTermId = modelRef.termId;
        let termArray = termsData.filter((term) => term.id === selectedTermId);
        let term = termArray[0];
        if (term.isFixed) {
          modelRef.value = term.fixedFee;
        } else {
          let value = (salary * term.percentage) / 100;
          if (term.fee && value > term.fee) {
            value = term.fee;
          }
          modelRef.value = value;
        }
      }
    };

    onMounted(() => {
      fetchTerms();
      termId = computed(() => store.getters["workbenchStore/getTermId"]).value;
      console.log('termId :>> ', termId);

    });
    return {
      onClickCancel,
      onAddRevenue,
      modelRef,
      validate,
      validateInfos,
      resetFields,
      fetchTerms,
      termsFeesOptions,
      onAddVacancyRevenueDetails,
      vacancyId,
      candidateName,
      resumeId,
    };
  },
};
</script>


<style lang="scss">
@import "../styles/addRevenueDetails.scss";
</style>