<template>
  <div>
    <div class="vacancy-profile-main-wrapper">
      <div class="left-section">
        <div class="vacancy-profile-first-section">
          <div class="vacancy-profile-first-section-avatar">
            <a-avatar :size="100">
              <template #icon><UserOutlined /></template>
            </a-avatar>
          </div>

          <div class="vacancy-profile-vacancy-title-section">
            <div class="vacancy-profile-title-with-status">
              <div class="vacancy-profile-vacancy-title">
                {{ vacancyData.title }}
              </div>
              <div>
                <span
                  class="vacancy-profile-status-tag"
                  :style="[
                    vacancyData.status === 'ACTIVE'
                      ? { background: 'rgba(111, 207, 151, 0.4)' }
                      : { background: 'rgba(76, 81, 85, 0.5)' },
                  ]"
                >
                  {{ vacancyData.status }}
                </span>
              </div>
            </div>

            <div class="vacancy-profile-vacancy-level">
              Level:&nbsp;{{ vacancyData.level.name }}
            </div>
            <div class="vacancy-profile-vacancy-level">
              {{ vacancyData.experienceFrom }}&nbsp;-&nbsp;{{
                vacancyData.experienceTo
              }}
            </div>

            <div class="vacancy-profile-vacancy-type-section">
              <div class="vacancy-profile-vacancy-type-sub-section">
                <div class="vacancy-type-titles">Vac.Type</div>
                <div class="vacancy-type-responses">
                  {{ vacancyData.vacancyType.name }}
                </div>
              </div>
              <div class="vacancy-profile-vacancy-type-sub-section">
                <div class="vacancy-type-titles">Salary</div>
                <div class="vacancy-type-responses">
                  {{ vacancyData.salaryFrom }}
                </div>
              </div>
              <div class="vacancy-profile-vacancy-type-sub-section">
                <div class="vacancy-type-titles">Value</div>
                <div class="vacancy-type-responses">
                  {{ vacancyData.value }}
                </div>
              </div>
              <div class="vacancy-profile-vacancy-type-sub-section">
                <div class="vacancy-type-titles">Dis.Start Date</div>
                <div class="vacancy-type-responses">
                  {{ vacancyData.displayStartDate }}
                </div>
              </div>
              <div class="vacancy-profile-vacancy-type-sub-section">
                <div class="vacancy-type-titles">Dis.End Date</div>
                <div class="vacancy-type-responses">
                  {{ vacancyData.displayEndDate }}
                </div>
              </div>
            </div>
          </div>

          <div class="vacancy-profile-company-section">
            <div>
              <div
                class="vacancy-profile-company-section-sub-section"
                style="margin-bottom: 5px"
              >
                <div class="vacancy-type-titles">Company</div>
                <div class="vacancy-type-responses">
                  {{ vacancyData.account.name }}
                </div>
              </div>
              <div
                class="vacancy-profile-company-section-sub-section"
                style="margin-bottom: 5px"
              >
                <div class="vacancy-type-titles">Client Contact</div>

                <div
                  class="vacancy-type-responses"
                  v-for="(client, index) in vacancyData.clientPersons"
                  :key="index"
                >
                  {{ client }}
                </div>
              </div>
              <div
                class="vacancy-profile-company-section-sub-section"
                style="margin-bottom: 5px"
              >
                <div class="vacancy-type-titles">Status</div>
                <div class="vacancy-type-responses">
                  {{ vacancyData.vacancyStatus }}
                </div>
              </div>
              <div class="vacancy-profile-company-section-sub-section">
                <span class="vacancy-type-titles" style="margin-right: 10px"
                  >Start Date</span
                >
                <span class="vacancy-type-responses">{{
                  vacancyData.startDate
                }}</span>
              </div>
            </div>
            <div>
              <a style="color: #000000" href="/vacancy/vacancy-list">
                <CloseOutlined class="vacancy-profile-cross-icon" />
              </a>
            </div>
          </div>
        </div>

        <div class="vacancy-profile-second-section">
          <div class="vacancy-profile-additional-and-documents-wrapper">
            <a-tabs
              v-model:activeKey="tabActiveKey"
              :animated="false"
              @change="onChangeTab(tabActiveKey)"
            >
              <a-tab-pane key="additionalDetails" tab="Additional Details">
                <div class="vacancy-profile-city-originated-fee-wrapper">
                  <div style="width: 33%; margin-right: 15px">
                    <span
                      class="vacancy-profile-city-keys"
                      style="padding-left: 30px"
                      >City:</span
                    >
                    <span
                      class="vacancy-profile-city-values"
                      v-for="(city, index) in vacancyData.cities"
                      :key="index"
                      >{{ city }}</span
                    >
                    <div class="vacancy-profile-additional-details-select">
                      <a-select
                        disabled
                        v-model:value="verticalsValue"
                        mode="tags"
                        style="width: 100%"
                        placeholder="Select Vertical"
                        class="search-and-select-component"
                      >
                      </a-select>
                    </div>
                  </div>
                  <div style="width: 33%; margin-right: 15px">
                    <span class="vacancy-profile-city-keys"
                      >Originated By:</span
                    >
                    <span class="vacancy-profile-city-values">{{
                      vacancyData.originatedBy
                    }}</span>
                    <div class="vacancy-profile-additional-details-select">
                      <a-select
                        v-model:value="practiceAreasValue"
                        disabled
                        mode="tags"
                        style="width: 100%"
                        placeholder="Select PA"
                        class="search-and-select-component"
                      >
                      </a-select>
                    </div>
                  </div>
                  <div style="width: 33%">
                    <span
                      class="vacancy-profile-city-keys"
                      style="padding-left: 30px"
                      >Fee%:</span
                    >
                    <span class="vacancy-profile-city-values">{{
                      vacancyData.fee
                    }}</span>
                    <div class="vacancy-profile-additional-details-select">
                      <a-select
                        v-model:value="subPracticeAreasValue"
                        mode="tags"
                        disabled
                        style="width: 100%"
                        placeholder="Select SPA"
                        class="search-and-select-component"
                      >
                      </a-select>
                    </div>
                  </div>
                </div>

                <div class="vacancy-profile-cic-and-team-wrapper">
                  <div class="vacancy-profile-lead-cic-wrapper">
                    <div
                      v-for="(cic, index) in vacancyData.cics"
                      :key="index"
                      style="display: flex"
                    >
                      <div class="vacancy-profile-cic-wrapper">
                        <div
                          class="vacancy-profile-div-wrappers"
                          v-if="cic.lead"
                        >
                          <span class="vacancy-profile-lead-cic-keys"
                            >Lead CIC:</span
                          ><span class="vacancy-profile-lead-cic-response">{{
                            cic.name
                          }}</span>
                        </div>
                        <div class="vacancy-profile-div-wrappers" v-else>
                          <span class="vacancy-profile-lead-cic-keys"
                            >CIC{{ index + 1 }}:</span
                          >
                          <span class="vacancy-profile-lead-cic-response">{{
                            cic.name
                          }}</span>
                        </div>
                      </div>
                      <div class="vacancy-profile-revenue-wrapper">
                        <div class="vacancy-profile-div-wrappers">
                          <span class="vacancy-profile-lead-cic-keys"
                            >Revenue Share:</span
                          ><span class="vacancy-profile-lead-cic-response">{{
                            cic.revenue
                          }}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="vacancy-profile-team-wrapper">
                    <div
                      class="vacancy-profile-team-sub-wrapper"
                      v-for="(team, index) in vacancyData.teams"
                      :key="index"
                    >
                      <div class="vacancy-profile-team-section">
                        <div>
                          <span class="vacancy-profile-lead-cic-keys"
                            >Team {{ index + 1 }}:</span
                          ><span class="vacancy-profile-lead-cic-response">{{
                            team.teamName
                          }}</span>
                        </div>
                        <div>
                          <span class="vacancy-profile-lead-cic-keys"
                            >Revenue Share:</span
                          ><span class="vacancy-profile-lead-cic-response">{{
                            team.teamRevenue
                          }}</span>
                        </div>
                      </div>
                      <div class="vacancy-profile-sub-team-wrapper">
                        <span class="vacancy-profile-lead-cic-keys"
                          >Sub Team {{ index + 1 }}:</span
                        ><span class="vacancy-profile-lead-cic-response">{{
                          team.subTeamName
                        }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </a-tab-pane>
              <a-tab-pane key="documents" tab="Documents">
                <div>
                  <a-table
                    class="documentsListing"
                    :columns="documentColumn"
                    :data-source="documentList"
                    :pagination="false"
                    :row-selection="documentRowSelection"
                    :rowKey="(record) => record.id"
                  >
                  </a-table>
                </div>
              </a-tab-pane>
              <a-tab-pane
                key="billing"
                tab="Billing"
                v-if="
                  vacancyData.vacancyType.name === 'Retained' ||
                  (vacancyData.vacancyType.name === 'Contingent' &&
                    vacancyData.vacancyStatus === 'Placed by Vito')
                "
              >
                <div style="display: flex; width: 100%; align-items: baseline">
                  <div style="margin-left: auto; margin-right: 20px">
                    <a-select
                      ref="select"
                      v-model:value="showInvoice"
                      style="width: 120px"
                      class="vacancy-profile-add-invoice-select"
                      @focus="focus"
                      placeholder="Add Invoice"
                      @change="addInvoice"
                      :disabled="pendingInvoice === 0"
                    >
                      <a-select-option
                        value="Retained"
                        :disabled="
                          vacancyData.vacancyStatus === 'Placed by Vito'
                        "
                        >Retained</a-select-option
                      >
                      <a-select-option value="Contingent"
                        >Contingent</a-select-option
                      >
                    </a-select>
                  </div>
                  <div>
                    <span class="billing-total-amount-text">
                      Billing Invoice :</span
                    >
                    <span class="billing-total-amount">{{
                      vacancyData.value
                    }}</span>
                  </div>
                </div>

                <div
                  style="
                    display: flex;
                    justify-content: space-between;
                    margin-top: 20px;
                  "
                >
                  <div>
                    <div>
                      <span class="billing-details-keys">Terms :</span>
                      <span class="billing-details-response">
                        {{ vacancyData.term }}</span
                      >
                    </div>
                    <div>
                      <span class="billing-details-keys">Offered Salary :</span>
                      <span class="billing-details-response">
                        {{ vacancyData.salaryFrom }}
                      </span>
                    </div>
                  </div>
                  <div>
                    <div>
                      <span class="billing-details-keys">Total Invoice :</span>
                      <span class="billing-details-response">
                        {{ totalInvoice }}
                      </span>
                    </div>
                    <div>
                      <span class="billing-details-keys"
                        >Joining Details :</span
                      >
                      <span class="billing-details-response">{{
                        vacancyData.joiningDate
                      }}</span>
                    </div>
                  </div>
                  <div>
                    <div>
                      <span class="billing-details-keys"
                        >Pending Invoice :</span
                      >
                      <span class="billing-details-response">
                        {{ pendingInvoice }}</span
                      >
                    </div>
                    <div>
                      <span class="billing-details-keys"
                        >Book Revenue In :</span
                      >
                      <span class="billing-details-response">{{
                        vacancyData.revenueDate
                      }}</span>
                    </div>
                  </div>
                  <div>
                    <div>
                      <u
                        class="billing-details-keys"
                        style="cursor: pointer"
                        @click="onAddRevenueDetails"
                        >+ Add Revenue Details</u
                      >
                    </div>
                  </div>
                </div>
                <a-modal
                  class="column-modal"
                  title=""
                  footer=""
                  v-model:visible="addRevenueDetails"
                  :closable="false"
                  centered
                  @ok="addRevenueOk"
                  width="500px"
                >
                  <div>
                    <div style="width=80%">
                      <AddRevenueDetails @onClickCancel="onClickCancel" />
                    </div>
                  </div>
                  <!-- <template #footer>
                    <a-button> </a-button>
                  </template> -->
                </a-modal>
                <div>
                  <a-table
                    class="documentsListing"
                    :columns="billingColumn"
                    :data-source="invoicetList"
                    :pagination="false"
                    :rowKey="(record) => record.key"
                  >
                  </a-table>
                  <a-form
                    :ref="formRef"
                    style="display: flex; background: white"
                    v-if="showInvoiceForm"
                  >
                    <a-input
                      placeholder="Enter"
                      v-model:value="modelRef.amount"
                      style="width: 40%; margin: 5px 10px 5px 5px"
                    />
                    <a-date-picker
                      placeholder="Enter"
                      v-model:value="modelRef.invoiceDate"
                      style="width: 50%; margin: 5px 10px 5px 5px"
                    />
                    <a-select
                      ref="select"
                      v-model:value="modelRef.entityId"
                      style="width: 50%; margin-right: 5px; margin-top: 6px"
                      :options="entityOptions"
                    />
                    <a-input
                      placeholder="Enter"
                      v-model:value="modelRef.remarks"
                      style="width: 50%; margin: 5px 5px 5px 5px"
                    />
                    <a-label style="width: 50%; margin: 10px 20px 0 15px"
                      >Pending</a-label
                    >
                    <a-label style="width: 50%; margin: 10px 0 0 0">{{
                      showInvoice
                    }}</a-label>
                    <div style="margin-right: 10px">
                      <CloseOutlined
                        style="font-size: 12px; color: red"
                        @click="addInvoice"
                      />
                      <CheckOutlined
                        style="font-size: 12px; color: green"
                        @click="createInvoice"
                      />
                    </div>
                  </a-form>
                </div>
              </a-tab-pane>
            </a-tabs>
          </div>
        </div>

        <div class="vacancy-profile-third-section">
          <div class="attached-candidates-listing-container">
            <div class="attached-candidates-container">
              <a-tabs
                v-model:activeKey="tabKey"
                :animated="false"
                class="listing-tabs"
              >
                <a-tab-pane
                  key="candidates"
                  tab="Selected Candidates"
                  class="tab-pane-container"
                >
                  <a-divider />
                  <div class="tab-container">
                    <div class="search-container">
                      <a-input-search
                        v-model:value="searchValue"
                        class="header-search-box"
                        placeholder="Search"
                        @search="searchCandidate(searchValue)"
                      />
                      <a-popover
                        v-model:visible="filterVisibility"
                        trigger="click"
                        placement="bottom"
                        style="width: 200px"
                      >
                        <template #content>
                          <div class="filter-wrapper">
                            <div class="filter-with-reset-text">
                              <div style="color: #989a9d">Filters</div>
                              <div
                                class="reset-changes-text"
                                @click="resetFilter"
                              >
                                Reset Changes
                              </div>
                            </div>
                            <div>
                              <div class="procedure-stage-text">
                                Procedure Stage
                              </div>
                              <div class="filter-popover-container">
                                <div
                                  class="tags-container"
                                  v-for="filter in procedureStageData"
                                  :key="filter.id"
                                >
                                  <a-checkable-tag
                                    :checked="
                                      selectedFilters.includes(filter.id)
                                    "
                                    @change="
                                      (checked) => handleChange(filter, checked)
                                    "
                                  >
                                    {{ filter.name }}
                                  </a-checkable-tag>
                                </div>
                              </div>

                              <div class="cancel-with-apply-filter">
                                <div style="margin-left: auto">
                                  <a-button
                                    type="link"
                                    class="cancel-text"
                                    @click="cancelFilter"
                                  >
                                    Cancel
                                  </a-button>
                                </div>

                                <div>
                                  <a-button
                                    class="apply-filter-btn"
                                    @click="applyFilter"
                                    >Apply Filters</a-button
                                  >
                                </div>
                              </div>
                            </div>
                          </div>
                        </template>
                        <a-button
                          style="
                            height: 44px;
                            border-radius: 13px;
                            background: white;
                            border: none;
                            font-family: 'Poppins Medium';
                          "
                          type="ghost"
                          @click="filterShow"
                        >
                          <FilterOutlined style="font-size: 18px" />
                          Filter
                          <DownOutlined />
                        </a-button>
                      </a-popover>
                    </div>
                    <div class="listing-table">
                      <div class="selected-filters-list-section">
                        <a-checkable-tag
                          v-for="name in displayFilters"
                          :key="name"
                          checked
                        >
                          {{ name }}
                        </a-checkable-tag>
                      </div>
                      <a-table
                        :columns="listedCandidateColumns"
                        :data-source="candidatesData"
                        :row-selection="candidateListingRowSelection"
                        :pagination="false"
                        :rowKey="(record) => record.candidateName.id"
                      >
                        <template #candidateName="{ text }">
                          <div style="display: flex">
                            <div
                              class="candidate-image-icon"
                              v-if="text.imageUrl"
                            >
                              <img
                                v-if="text"
                                src="{{text}}"
                                alt="Candidate image"
                              />
                            </div>
                            <div class="candidate-image-icon" v-else>
                              <a-avatar>
                                <template #icon><UserOutlined /></template>
                              </a-avatar>
                              &nbsp;
                            </div>
                            <div style="padding-top: 5px">
                              <span
                                class="listing-candidate-name"
                                @click="fetchCandidateProfile(text.id)"
                              >
                                {{ text.name }}
                              </span>
                              <div>
                                <span>
                                  <u
                                    class="candidate-listing-resume-id"
                                    v-if="text.resumeId"
                                    >{{ text.resumeId }}</u
                                  >
                                </span>
                                <a-button
                                  type="link"
                                  class="candidate-listing-taxonomy-btn"
                                  @click="showTaxonomy(text.id)"
                                >
                                  <IdcardOutlined
                                    class="candidate-profile-social-media-icon"
                                  />
                                </a-button>
                                <a-button
                                  type="link"
                                  v-if="
                                    text.socialAccounts.linkedIn &&
                                    Object.keys(text.socialAccounts.linkedIn)
                                      .length !== 0
                                  "
                                  style=""
                                  class="candidate-listing-social-media-btn"
                                  href="text.socialAccounts.linkedIn.link"
                                >
                                  <LinkedinFilled
                                    circle
                                    class="candidate-profile-social-media-icon"
                                  />
                                </a-button>
                              </div>
                            </div>
                          </div>
                        </template>
                        <template #rating="{ text }">
                          <a-rate
                            :value="parseFloat(text / 2)"
                            disabled
                            allowHalf
                            count="4"
                          />
                        </template>
                        <template #ctc="{ text }">
                          <div class="candidate-list-texts">
                            {{ text }}
                          </div>
                        </template>
                        <template #city="{ text }">
                          <div class="candidate-text-color-light">
                            {{ text }}
                          </div>
                        </template>
                        <template #cic="{ text }">
                          <div v-for="item in text" :key="item">
                            {{ item }}
                          </div>
                        </template>
                        <template #tags="{ text }">
                          <a-tag
                            v-if="text"
                            color="#F3F5F7"
                            :closable="false"
                            class="candidate-list-status-tags"
                          >
                            {{ text }}
                          </a-tag>
                          <span v-else>-</span>
                        </template>
                        <template #customTitle>
                          <a-button
                            @click="showColumnManager"
                            type="link"
                            style="color: #4c5155"
                          >
                            <MenuOutlined />
                          </a-button>
                        </template>
                      </a-table>
                    </div>
                    <div class="view-more-btn">
                      <div v-if="showViewMore">
                        <a-button type="link" @click="fetchMoreCandidates">
                          <template #icon>
                            <PlusCircleOutlined />
                            View More
                          </template>
                        </a-button>
                      </div>
                    </div>
                  </div>
                </a-tab-pane>
              </a-tabs>
              <!-- Filter Modal -->

              <!-- Manage Columns Modal -->

              <a-modal
                class="filter-modal"
                title=""
                width="500px"
                v-model:visible="taxonomyModalVisibility"
                :closable="false"
                centered
                footer=""
                destroyOnClose
              >
                <TaxonomyWidget :id="taxonomy_id" />
              </a-modal>
            </div>
          </div>
        </div>

        <div class="vacancy-profile-attach-candidate-section"></div>
      </div>

      <div class="right-section">
        <ActionBar @updateAttachedCandidateListing="updateCandidateListing" />
      </div>
    </div>
  </div>
</template>

<script>
import ActionBar from "./rightSideBar.vue";
import TaxonomyWidget from "../../candidate/components/widget.vue";
import AddRevenueDetails from "../components/AddRevenueDetails.vue";
import { message } from "ant-design-vue";
import { defineComponent, reactive, ref, onMounted, computed } from "vue";
import {
  DownOutlined,
  LinkedinFilled,
  FilterOutlined,
  IdcardOutlined,
  UserOutlined,
  PlusCircleOutlined,
  MenuOutlined,
  CloseOutlined,
  CheckOutlined,
} from "@ant-design/icons-vue";
import { useRoute, useRouter } from "vue-router";
import Services from "@/services/apis";
import Utils from "../../../utils";
import { useStore } from "vuex";
import utils from "../../../utils";

let addRevenueDetails = ref(false);

let taxonomy_id = ref("");
let selectedFilters = reactive([]);
let displayFilters = reactive([]);
let selectedFiltersNames = reactive([]);

let candidatesData = reactive([]);
let procedureStageData = reactive([]);

let vacancyData = reactive({
  title: "-",
  level: "-",
  vacancyId: "-",
  status: "-",
  experienceFrom: "-",
  experienceTo: "-",
  account: {
    id: "-",
    name: "-",
    companyId: "-",
    logo: "-",
  },
  clientPersons: [
    {
      id: "-",
      name: "-",
    },
  ],
  vacancyStatus: "-",
  startDate: "-",
  endDate: "-",
  vacancyType: "-",
  city: "-",
  originatedBy: "-",
  fee: 0,
  verticals: [
    {
      id: "-",
      name: "-",
    },
  ],
  practiceAreas: [
    {
      id: "-",
      name: "-",
    },
  ],
  subPracticeAreas: [
    {
      id: "-",
      name: "-",
    },
  ],
  salaryFrom: "-",
  salaryTo: "-",
  value: "-",
  cic: [
    {
      user: {
        id: "-",
        name: "-",
      },
      isLead: true,
      revShare: 0,
    },
  ],
  teams: [
    {
      team: {
        id: "-",
        name: "-",
      },
      subTeam: {
        id: "-",
        name: "-",
      },
      revShare: 0,
    },
  ],
  documents: [
    {
      id: "-",
      name: "-",
      type: "-",
      url: "-",
      size: 0,
    },
  ],
});

let verticalsValue = ref([]);
let practiceAreasValue = ref([]);
let subPracticeAreasValue = ref([]);

const documentColumn = [
  {
    title: "File Name",
    dataIndex: "fileName",
    key: "fileName",
    align: "left",
  },
  {
    title: "File Size",
    dataIndex: "fileSize",
    slots: { customRender: "fileSize" },
    key: "fileSize",
    align: "left",
  },
  {
    title: "Document Type",
    dataIndex: "docType",
    key: "docType",
    align: "left",
  },
  {
    title: "Description",
    dataIndex: "description",
    key: "description",
    align: "left",
  },
  {
    title: "Date",
    dataIndex: "date",
    key: "date",
    align: "left",
  },
];
let documentList = reactive([]);

const billingColumn = [
  {
    title: "Amount",
    dataIndex: "amount",
    key: "amount",
    width: 130,
  },
  {
    title: "Invoice Date",
    dataIndex: "invoiceDate",
    slots: { customRender: "invoiceDate" },
    key: "invoiceDate",
    width: 135,
  },
  {
    title: "Entity Name",
    dataIndex: "entityName",
    key: "entityName",
  },
  {
    title: "Account Notes",
    dataIndex: "notes",
    key: "notes",
  },
  {
    title: "Billing Status",
    dataIndex: "billingStatus",
    key: "billingStatus",
  },
  {
    title: "Invoice Type",
    dataIndex: "invoiceType",
    key: "invoiceType",
  },
];
let invoicetList = reactive([]);

const entityOptions = ref([]);

const getEntityListing = () => {
  let companyId = vacancyData.account.id;
  Services.getAccountProfile(companyId)
    .then((response) => {
      const { data } = response;
      let entities = data.entities;
      entityOptions.value = entities.map(
        ({ id: value, name: label, ...rest }) => ({
          value,
          label,
          ...rest,
        })
      );
    })
    .catch((e) => {});
};

export default defineComponent({
  components: {
    TaxonomyWidget,
    LinkedinFilled,
    IdcardOutlined,
    DownOutlined,
    FilterOutlined,
    UserOutlined,
    PlusCircleOutlined,
    MenuOutlined,
    CloseOutlined,
    ActionBar,
    AddRevenueDetails,
    CheckOutlined,
  },

  setup() {
    let totalInvoice = ref(0);
    let pendingInvoice = ref(0);
    const formRef = ref();
    let termId = "";
    const modelRef = reactive({
      vacancyId: id,
      accountId: vacancyData.account.id,
      entityId: "",
      type: "",
      amount: 0,
      remarks: "",
      invoiceDate: "",
    });
    let attacheCandidatesCount = ref();
    let showViewMore = ref(false);
    const store = useStore();
    const {
      params: { id },
    } = useRoute();
    const router = useRouter();

    const setVacancyId = () => {
      store.dispatch("workbenchStore/setVacancyId", id);
    };
    const taxonomyModalVisibility = ref(false);

    const fetchCandidateProfile = (id) => {
      router.push(`/candidates/${id}/detail`);
    };
    let pageCount = 0;
    const searchValue = ref("");
    const filterVisibility = ref(false);
    const filterShow = () => {
      filterVisibility.value = !filterVisibility.value;
    };
    const getVacancyProfileData = async (id) => {
      let tempData;
      await Services.getVacancyProfile(id).then((response) => {
        const { data } = response;
        if (data) {
          tempData = {
            title: data.title ? data.title : "-",
            level: data.level ? data.level : "-",
            vacancyId: data.vacancyId ? data.vacancyId : "-",
            status: data.status ? data.status : "-",
            experienceFrom: data.experienceFrom ? data.experienceFrom : "-",
            experienceTo: data.experienceTo ? data.experienceTo : "-",
            account: {
              id: data.account[0].id ? data.account[0].id : "-",
              name: data.account[0].name ? data.account[0].name : "-",
            },
            clientPersons: data.clientPersons
              ? data.clientPersons.map((x) => x.name)
              : "-",
            vacancyStatus: data.vacancyStatus ? data.vacancyStatus.name : "-",
            startDate: data.startDate
              ? Utils.displayLongDate(data.startDate)
              : "-",
            endDate: data.endDate ? Utils.displayLongDate(data.endDate) : "-",
            vacancyType: data.vacancyType ? data.vacancyType : "-",
            cities: data.city ? data.city : "-",
            originatedBy: data.originatedBy ? data.originatedBy : "-",
            fee: data.term ? Utils.term(data.term) : "-",
            term: data.term
              ? data.term.isFixed
                ? data.term.fixedFee
                : data.term.percentage + "%"
              : "-",
            verticals: data.verticals
              ? ref(data.verticals.map((x) => x.name))
              : [],
            practiceAreas: data.practiceAreas
              ? ref(data.practiceAreas.map((x) => x.name))
              : [],
            subPracticeAreas: data.subPracticeAreas
              ? ref(data.subPracticeAreas.map((x) => x.name))
              : [],
            salaryFrom: data.salaryFrom ? Utils.rounding(data.salaryFrom) : "-",
            salaryTo: data.salaryTo ? data.salaryTo : " ",
            value: data.value ? Utils.rounding(data.value) : "-",
            displayEndDate: data.displayEndDate
              ? Utils.displayLongDate(data.displayEndDate)
              : "-",
            displayStartDate: data.displayStartDate
              ? Utils.displayLongDate(data.displayStartDate)
              : "-",
            joiningDate: data.joiningDate
              ? Utils.dispalyDateMonthYear(data.joiningDate)
              : "-",
            revenueDate: data.revenueDate
              ? Utils.dispalyDateMonthYear(data.revenueDate)
              : "-",
            cics: data.cic.length
              ? data.cic.map((x) => {
                  let tempObject = {
                    name: x.user.name,
                    id: x.user.id,
                    lead: x.isLead,
                    revenue: x.revShare ? x.revShare : "-",
                  };
                  return tempObject;
                })
              : [],
            teams: Object.keys(data.teams[0]).length
              ? data.teams.map((x) => {
                  let tempObject = {
                    teamName: x.team && x.team.name ? x.team.name : "-",
                    teamRevenue:
                      x.team && x.team.revShare ? x.team.revShare : "-",
                    subTeamName:
                      x.subTeam && x.subTeam.name ? x.subTeam.name : "-",
                  };
                  return tempObject;
                })
              : [],
            documents: data.documents.length
              ? data.documents.map((x) => {
                  let tempObject = {
                    id: x.id,
                    fileName: x.name.split("/").slice(-1),
                    fileSize: x.size ? x.size + "kb" : 0,
                    docType: x.type ? x.type : "-",
                    description: x.description ? x.description : "-",
                    date: Utils.displayLongDate(x.createdAt),
                  };
                  return tempObject;
                })
              : [],
          };
          termId = data.term.id;
          store.dispatch("workbenchStore/vacancyRevenueTermId", termId);

          store.dispatch("workbenchStore/updateCompanyId", data.account[0].id);
        }
        Object.assign(verticalsValue, tempData.verticals);
        Object.assign(practiceAreasValue, tempData.practiceAreas);
        Object.assign(subPracticeAreasValue, tempData.subPracticeAreas);
        Object.assign(vacancyData, tempData);
        Object.assign(documentList, tempData.documents);
        if (tempData.cics.length < 4) {
          for (let i = tempData.cics.length; i < 4; i++) {
            let tempObject = {
              name: "-",
              id: i,
              lead: false,
              revenue: "-",
            };
            tempData.cics.push(tempObject);
          }
        }
        if (tempData.teams.length < 3) {
          for (let i = tempData.teams.length; i < 3; i++) {
            let tempObject = {
              teamName: "-",
              teamRevenue: "-",
              subTeamName: "-",
              subTeamRevenue: "-",
            };
            tempData.teams.push(tempObject);
          }
        }
      });
    };

    // candidate Listing
    let listedCandidateColumns = reactive([
      {
        title: "Candidate",
        default: true,
        dataIndex: "candidateName",
        key: "candidateName",
        slots: { customRender: "candidateName" },
        width: 180,
      },
      {
        title: "Rating",
        default: true,
        dataIndex: "rating",
        key: "rating",
        slots: { customRender: "rating" },
      },
      {
        title: "Compensation",
        default: true,
        dataIndex: "ctc",
        key: "ctc",
        slots: { customRender: "ctc" },
        width: 120,
        align: "center",
      },
      {
        title: "City",
        default: true,
        dataIndex: "city",
        key: "city",
        slots: { customRender: "city" },
        align: "center",
      },
      {
        title: "T.W Exp",
        default: true,
        dataIndex: "totalWorkExp",
        key: "totalWorkExp",
        align: "center",
      },
      {
        title: "Work Exp.",
        default: true,
        dataIndex: "workExperience",
        key: "workExperience",
        align: "center",
      },
      {
        title: "Taxonomy",
        default: true,
        dataIndex: "taxonomy",
        key: "taxonomy",
        align: "center",
      },
      {
        title: "Call",
        default: true,
        dataIndex: "call",
        key: "call",
        align: "center",
      },

      {
        title: "CIC Key",
        default: true,
        dataIndex: "cic",
        key: "cic",
        slots: { customRender: "cic" },
      },
      {
        title: "Tags",
        default: false,
        dataIndex: "tags",
        key: "tags",
        align: "center",
        slots: { customRender: "tags" },
        width: 100,
      },
      {
        colSpan: 1,
        width: 0,
        key: "manageColumns",
        slots: { title: "customTitle" },
      },
    ]);

    const candidateListingRowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        let selectedCandidates = [];
        selectedRows.map((x) => {
          let candidateObject = {
            id: x.candidateName.id,
            name: x.candidateName.name,
          };
          selectedCandidates.push(candidateObject);
        });
        addSelectedCandidates(selectedCandidates);
      },
    };

    const documentRowSelection = {
      onChange: (selectedRowKeys, SelectedRows) => {
        console.log("selectedRowKeys :>> ", selectedRowKeys);
      },
    };

    const searchCandidate = (value) => {
      pageCount = 0;
      fetchCandidates(value, selectedFilters);
    };

    const fetchMoreCandidates = () => {
      pageCount++;
      if (searchValue.value) {
        fetchCandidates(searchValue.value, selectedFilters);
      } else {
        fetchCandidates();
      }
    };

    const fetchCandidates = async (value, status) => {
      let listData = [];
      let payload = {
        id: id,
        q: "",
        pageNo: pageCount,
        status: status,
      };
      if (pageCount > 0) {
        listData = candidatesData;
      } else {
        candidatesData.splice(0);
      }
      if (value) {
        payload = {
          id: id,
          q: value,
          pageNo: pageCount,
          mode: "LISTING",
          status: status,
        };
      }

      await Services.getCandidatesAttachedToVacancy(payload)
        .then((response) => {
          const { data } = response;
          data &&
            data.count &&
            data.candidates.map((x) => {
              listData.push({
                candidateName: {
                  imageUrl: x.imageUrl,
                  name: x.name ? x.name : "-",
                  resumeId: x.resumeId ? x.resumeId : "-",
                  id: x.id,
                  socialAccounts: x.socialAccounts,
                },
                rating: ref(x.rating),
                city: x.city ? x.city : "-",
                workExperience: x.workExperience
                  ? x.workExperience + " Years"
                  : "-",
                totalWorkExp: x.totalWorkExp ? x.totalWorkExp + " Years" : "-",
                ctc: x.compensation ? x.compensation : "-",
                cic: x.cic.length ? x.cic.map((x) => x.name) : "-",
                taxonomy: x.taxonomy ? x.taxonomy.map((x) => x.name) : "-",
                tags: x.status ? x.status : "",
                call: x.call ? x.call : "-",
              });

              let vacancyStatus = vacancyData.vacancyStatus;
              if (
                vacancyStatus === "Placed by Vito" &&
                x.status === "Offer accepted"
              ) {
                let candidateId = x.id;
                let resumeId = x.resumeId;
                let candidateName = x.name;
                store.dispatch(
                  "workbenchStore/vacancyProfileCandidateId",
                  candidateId
                );
                store.dispatch(
                  "workbenchStore/vacancyProfileResumeId",
                  resumeId
                );
                store.dispatch(
                  "workbenchStore/vacancyRevenueCandidateName",
                  candidateName
                );
              }
            });
          attacheCandidatesCount.value = data.count;
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
      Object.assign(candidatesData, listData);
      showViewMore.value =
        candidatesData.length < attacheCandidatesCount.value &&
        attacheCandidatesCount.value > 10;
    };

    const addSelectedCandidates = (selectedCandidates) => {
      store.dispatch(
        "workbenchStore/setSelectedCandidates",
        selectedCandidates
      );
    };

    const handleChange = (tag, checked) => {
      let filters = selectedFilters;
      let filterNames = selectedFiltersNames;
      let nextSelectedTags;
      let nextSelectedTagNames;
      if (checked) {
        filters.push(tag.id);
        filterNames.push(tag.name);
      } else {
        nextSelectedTags = filters.filter((filter) => filter !== tag.id);
        nextSelectedTagNames = filterNames.filter(
          (filter) => filter !== tag.name
        );
        filters = nextSelectedTags;
        filterNames = nextSelectedTagNames;
        selectedFilters.splice(0);
        selectedFiltersNames.splice(0);
      }
      Object.assign(selectedFilters, filters);
      Object.assign(selectedFiltersNames, filterNames);
    };

    const showTaxonomy = (id) => {
      taxonomy_id.value = id;
      taxonomyModalVisibility.value = true;
    };

    const applyFilter = () => {
      displayFilters.splice(0);
      if (selectedFilters.length) {
        Object.assign(displayFilters, selectedFiltersNames);
        candidatesData.splice(0);
        fetchCandidates("", selectedFilters);
      } else {
        candidatesData.splice(0);
        fetchCandidates("", "");
      }
      filterVisibility.value = false;
    };
    // Fetched Candidate Filter
    const fetchProcedureStageData = () => {
      let tempData = [];
      let payload = { q: "", mode: "AUTOCOMPLETE", types: ["PROCEDURE"] };
      Services.getProcedureTypeDetail(payload)
        .then((response) => {
          const { data } = response;
          tempData = data.data.map(({ id, name }) => ({
            id,
            name,
          }));
          Object.assign(procedureStageData, tempData);
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    };

    const resetFilter = () => {
      selectedFilters.splice(0);
      selectedFiltersNames.splice(0);
    };

    const cancelFilter = () => {
      filterVisibility.value = false;
    };

    const updateCandidateListing = (value) => {
      if (value) {
        fetchCandidates("", "");
      }
    };

    const addRevenueOk = (e) => {
      addRevenueDetails.value = false;
    };

    const onAddRevenueDetails = () => {
      console.log("vacancyData", vacancyData);
      if (vacancyData.vacancyStatus === "Placed by Vito") {
        addRevenueDetails.value = true;
      } else {
        message.error("Please Change Vacancy status to Placed By Vito!");
      }
    };
    const onClickCancel = (value) => {
      addRevenueDetails.value = value;
    };

    // Add Invoice Code
    let showInvoice = ref(undefined);
    let showInvoiceForm = ref(false);
    const addInvoice = (value) => {
      if (value === "Contingent" || value === "Retained") {
        modelRef.amount = pendingInvoice.value;
        showInvoiceForm.value = true;
        getEntityListing();
      } else {
        showInvoiceForm.value = false;
        showInvoice.value = undefined;
      }
    };

    const createInvoice = () => {
      modelRef.vacancyId = id;
      modelRef.accountId = vacancyData.account.id;
      modelRef.type = showInvoice.value;
      modelRef.invoiceDate = Utils.displayYearFirstNew(modelRef.invoiceDate);
      modelRef.amount = parseInt(modelRef.amount);
      if (modelRef.amount <= pendingInvoice.value) {
        Services.createVacancyInvoice(modelRef)
          .then((response) => {
            const { data } = response;
            fetchBillingList(id);
            resetInvoiceForm();
            showInvoiceForm.value = false;
            showInvoice.value = undefined;
          })
          .catch((err) => {});
      } else {
        message.error("Amount should not greater than pending invoice!");
      }
    };

    const onChangeTab = (e) => {
      if (e === "billing") {
        let vacancyId = id;
        fetchBillingList(vacancyId);
      }
    };

    const fetchBillingList = async (vacancyId) => {
      let listData = [];
      let payload = {
        vacancyId: vacancyId,
        pageNo: 0,
        mode: "LISTING",
      };
      await Services.getInvoiceListing(payload)
        .then((response) => {
          const { data } = response;
          data &&
            data.count &&
            data.data.map((x) => {
              listData.push({
                key: x.id,
                amount: x.revenue,
                invoiceDate: utils.displayLongDate(x.invoiceDate),
                entityName: x.account.entity.name,
                notes: x.remarks,
                billingStatus: x.invoiceStatus,
                invoiceType: x.type,
              });
              totalInvoice.value += x.revenue;
            });
        })
        .catch((e) => {});
      pendingInvoice.value = vacancyData.value - totalInvoice.value;
      Object.assign(invoicetList, listData);
    };

    const resetInvoiceForm = () => {
      modelRef.entityId = "";
      modelRef.type = "";
      modelRef.amount = 0;
      modelRef.remarks = "";
      modelRef.invoiceDate = "";
    };

    onMounted(() => {
      getVacancyProfileData(id);
      fetchCandidates("", "");
      setVacancyId();
      fetchProcedureStageData();
    });

    return {
      modelRef,
      formRef,
      // onSubmit,
      taxonomyModalVisibility,
      taxonomy_id,
      fetchProcedureStageData,
      fetchCandidateProfile,
      procedureStageData,
      filterVisibility,
      filterShow,
      searchValue,
      tabActiveKey: ref("additionalDetails"),
      onChangeTab,
      vacancyData,
      verticalsValue,
      practiceAreasValue,
      subPracticeAreasValue,
      documentColumn,
      documentList,
      billingColumn,
      invoicetList,
      documentRowSelection,
      candidatesData,
      attacheCandidatesCount,
      fetchCandidates,
      fetchMoreCandidates,
      searchCandidate,
      showViewMore,
      listedCandidateColumns,
      candidateListingRowSelection,
      pageCount,
      selectedFilters,
      displayFilters,
      selectedFiltersNames,
      handleChange,
      applyFilter,
      resetFilter,
      cancelFilter,
      showTaxonomy,
      updateCandidateListing,
      addRevenueDetails,
      totalInvoice,
      pendingInvoice,
      addRevenueOk,
      onAddRevenueDetails,
      onClickCancel,
      entityOptions,
      addInvoice,
      createInvoice,
      showInvoiceForm,
      showInvoice,
    };
  },
});
</script>

<style lang="scss">
@import "../styles/vacancyProfile.scss";
</style>
